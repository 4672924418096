
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class RoleManagement extends Vue {
    value= '';
  options = [
    {
      value: "Option1",
      label: "Super Admin",
    },
    {
      value: "Option2",
      label: "Connection Manager",
    },
    {
      value: "Option3",
      label: "Data Manager",
    },
    {
      value: "Option4",
      label: "User",
    },
    {
      value: "Option5",
      label: "Read Only User",
    },
  ];
  defaultResourcesPermission = [
    {
      sectionName: "User",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Account",
      read: true,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Platform",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Account Billing",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Crons Manager",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Customization",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
    {
      sectionName: "Role Management",
      read: false,
      write: false,
      update: false,
      delete: false,
    }
  ];
}
